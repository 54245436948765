<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      'Meu PIX': 'Meu PIX',
      'Save': 'Salvar',
      'Type': 'Tipo',
      'Key': 'Chave',
      'The key is required.': 'A chave é obrigatório',
      'The type of the key is mandatory.': 'O tipo de chave é obrigatório'
    },
    es: {
      'Meu PIX': 'Meu PIX',
      'Save': 'Salvar',
      'Type': 'Escribe',
      'Key': 'llave',
      'The key is required.': 'Se requiere la clave',
      'The type of the key is mandatory.': 'El tipo de clave es obligatorio'
    }
  },
  components: { Layout, VclList, Stat },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      loading: false,

      address: '',
      default: null,
      list: null,

      statData: [
        {
          title: "Status",
          value: "pending",
        },
        {
          title: "Address",
          value: "–",
        },
      ],

      validation: {
        modal: false,
        loading: false,
        id: null,
        address: "",
        code: "",
      }
    };
  },
  validations: {
    address: { required },
  },
  methods: {
    getUsdt() {
      api
        .get('user/usdt')
        .then((response) => {
          if (response.data.status == 'success') {
            this.default = response.data.default,
            this.list = response.data.list

            this.statData = [
              {
                title: "Status",
                value: response.data.default.status,
              },
              {
                title: "Address",
                value: response.data.default.address,
              },
            ]
          }
        })
    },
    usdtSubmit() {
      this.loading = true

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.loading = false
        return;
      } else {
        if (this.address) {
          api
            .post('user/usdt', {
              address: this.address,
            })
            .then(response => {
              if (response.data.status == 'success') {
                this.validation.id = response.data.usdt.id;
                this.validation.address = this.address;
                this.validation.code = '';
                this.validation.modal = true;

                this.address = ''
                this.$v.$reset()

                this.$noty.success('We send a validation code in your email.')
              } else {
                this.$noty.error(response.data.message)
              }

              this.loading = false
            })
            .catch(error => {
              if (error) {
                this.loading = false
              }
            })
        }
      }
    },
    confirmValidation() {
      this.validation.loading = true;

      if (this.validation.id) {
        api.post("user/usdt/validation", {
          id: this.validation.id,
          code: this.validation.code
        }).then((response) => {
          if (response.data.status == "success") {
            this.validation.modal = false;

            this.$noty.success('Your address was successfully validated.')
            this.getUsdt();
          } else {
            this.$noty.error(response.data.message)
          }
        }).catch((error) => {
          if (error) {
            this.validation.loading = false
          }
        }).finally(() => {
          this.validation.loading = false;
        });
      }
    },
  },
  mounted() {
    this.getUsdt()
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ t('My Wallet') }}</h3>
        <p></p>
      </div>
    </div>

    <div class="d-flex" style="gap: 20px">
      <div v-for="stat of statData" :key="stat.icon" class="">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="card">
      <div class="card-body table-responsive">
        <b-form class="p-3" @submit.prevent="usdtSubmit">
          <p>{{ t("To activate your wallet, enter the receiving address.") }}</p>
          <hr class="my-4">
          <b-form-group id="network" :label="t('Network')" label-for="network">
            <div class="d-flex align-items-center border rounded p-2 mb-3">
              <div class="mr-2">
                <img style="max-width: 50px" src="@/assets/images/logo-tron.png" />
              </div>
              <div>
                USDT TRC-20
              </div>
            </div>
          </b-form-group>
          <b-form-group id="address" :label="t('Address')" label-for="address">
            <b-form-input id="address" v-model="address" type="text" :class="{ 'is-invalid': $v.address.$error }"></b-form-input>
            <div v-if="$v.address.$error" class="invalid-feedback">
              <span v-if="!$v.address.required">{{ t('The address is required.') }}</span>
            </div>
          </b-form-group>
          <div class="my-4">
            <b-button :disabled="this.loading == true || !this.address" type="submit" variant="default" class="btn-lg">
              {{ t('Validate') }}
              <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
            </b-button>
          </div>
          <div class="d-flex align-items-center bg-soft-default rounded p-3">
            <i class="bx bx-info-circle font-size-18 mr-2"></i>
            <div>Be sure to enter the USDT TRC-20 network wallet or the transaction funds may be lost.</div>
          </div>
        </b-form>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        {{ t("Last receiving addresses") }}
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <vcl-list v-if="loading" class="col-lg-6"></vcl-list>
          <div v-else-if="list && list.length == 0">{{ t("No records found.") }}</div>
          <table v-else class="table table-centered table-nowrap">
            <thead class="thead-light">
              <tr>
                <th>Date</th>
                <th>Address</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td, index) in list" :key="index">
                <td>{{ td.date }}</td>
                <td>{{ td.address }}</td>
                <td>{{ td.status }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <b-modal v-model="validation.modal" modal-class="modal-right" hide-header hide-footer>
      <div v-if="validation.id">
        <h3 class="mb-4">Address Validation</h3>
        <b-form-group :label="t('Network')">
          <div class="d-flex align-items-center border rounded p-2">
            <div class="mr-2">
              <img style="max-width: 50px" src="@/assets/images/logo-tron.png" />
            </div>
            <div>
              USDT TRC-20
            </div>
          </div>
        </b-form-group>
        <b-form-group :label="t('Address')">
          <b-form-input v-model="validation.address" type="text" disabled></b-form-input>
        </b-form-group>
        <b-form-group :label="t('Code')">
          <b-form-input v-model="validation.code" type="text"></b-form-input>
        </b-form-group>
        <div class="d-flex justify-content-between mt-5">
          <b-button variant="default" v-on:click="confirmValidation()" :disabled="validation.loading">
            Confirm
            <b-spinner v-if="validation.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </b-button>
          <button class="btn btn-outline-link" v-on:click="validation.modal = false;">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>